import { billingService, todoService, todoTaskService, unitsMeasureService, workflowDesignService } from '@/api';
import { Paging, SortOptions } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import {
  BillingItemTypeEnum,
  BillingStatusEnum,
  FinanceInvoiceStatusEnum,
  InvoiceColorEnum,
  InvoiceModeEnum,
  InvoiceTypeEnum,
  PaymentModeEnum,
  TransactionStatusEnum,
  TransactionTypeEnum
} from '@/resource/enum';
import {
  ApproveParamter,
  BillingDetail,
  BillingList,
  BillInvoiceDetailRequestList,
  BillInvoiceRequestListInfo,
  InstallRequestList,
  OtherRequestList,
  PackageAndLogisticsRequestList,
  ProductRequestList,
  ProductRequestQuery,
  TransactionList,
  WorkflowDesignResource
} from '@/resource/model';
import { TagsViewModule } from '@/store/modules/tags-view';
import { dateFormat, fuzzyQueryStr, messageError, showWarningConfirm, translation } from '@/utils';
import { Decimal } from 'decimal.js';
import { Message, TimelineItem } from 'element-ui';
import { MessageBoxInputData } from 'element-ui/types/message-box';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import InvoiceDetails from './invoice-details/invoice-details.vue';
import DetailedSummary from '../add-billing/detailed-summary/detailed-summary.vue';
import SelectTransactionDetails from './select-transaction-details/select-transaction-details.vue';
import { NormalSelectOptions } from '@/resource/model/common';
import { InitiateApproval } from '@/views/dialogs';
type ActiveTab = 'make' | 'package' | 'install' | 'other';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'BillingDetails',
  components: { InvoiceDetails, SelectTransactionDetails, DetailedSummary, InitiateApproval }
})
export default class BillingDetails extends Vue {
  public activeTab: ActiveTab = 'make';

  public billing: BillingDetail | null = null;

  /**
   * 数据是否有更新
   */
  public dataIsEdited = false;

  public id = Number(this.$route.query.id);
  public approvalDialogVisible: boolean = false;

  public businessId: number = 0;
  public workflowDesign: WorkflowDesignResource = {} as WorkflowDesignResource;

  public invoiceDetailsDialog = false;

  public invoiceDetails: BillInvoiceDetailRequestList | null = null;

  public makeTableOptions: OsTableOption<Partial<ProductRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count',
        fixPlace: 2
      },
      {
        prop: 'discountAmount',
        fixPlace: 2
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      }
      // {
      //   prop: 'taxAmount',
      //   fixPlace: 2
      // },
      // {
      //   prop: 'amountIncludingTax',
      //   fixPlace: 2
      // }
    ],
    defaultSort: { order: 'ascending', prop: 'itemCode' }
  };

  public makeColumnOptions: Array<OsTableColumn<ProductRequestList>> = [
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px',
      fixed: 'left'
    },
    {
      prop: 'itemCode',
      label: 'product.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      sortable: 'custom'
    },
    {
      prop: 'itemName',
      label: 'product.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'project.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'priceUnitName',
      label: 'customerProduct.chargeUnit',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'unitArea',
      label: 'billing.area',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'billing.paymentCount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).count ?? 0).toFixed(2);
      }
    },
    {
      prop: 'settlementArea',
      label: 'billing.priceCount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).priceBeforeTax ?? 0).toFixed(6);
      }
    },
    {
      prop: 'priceIncludingTax',
      label: 'billing.priceIncludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).priceIncludingTax ?? 0).toFixed(6);
      }
    },
    // {
    //   prop: 'taxRate',
    //   label: 'billing.taxRate',
    //   minWidth: '160px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).taxRate ?? 0).toFixed(2);
    //   }
    // },
    {
      prop: 'discountRate',
      label: 'billing.discountRate',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).discountRate ?? 0).toFixed(2);
      }
    },
    {
      prop: 'discountAmount',
      label: 'billing.discountAmount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).discountAmount ?? 0).toFixed(2);
      }
    },
    {
      prop: 'discountRemark',
      label: 'billing.discountRemark',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).amountExcludingTax ?? 0).toFixed(2);
      }
    },
    // {
    //   prop: 'taxAmount',
    //   label: 'billing.taxAmount',
    //   minWidth: '160px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).taxAmount ?? 0).toFixed(2);
    //   }
    // },

    // {
    //   prop: 'amountIncludingTax',
    //   label: 'billing.amountIncludingTax',
    //   minWidth: '180px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).amountIncludingTax ?? 0).toFixed(2);
    //   }
    // },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'saleUnitName',
      label: 'billing.saleUnit',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'saleCount',
      label: 'billing.saleCount',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ProductRequestList).finishWidth ?? '--'} × ${(row as ProductRequestList).finishHeight ??
          '--'}`;
      }
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemType',
      label: 'billing.itemType',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        const key = BillingItemTypeEnum[(rowData as ProductRequestList).itemType]
          ? `billingItemType.${BillingItemTypeEnum[(rowData as ProductRequestList).itemType]}`
          : '';
        return key ? translation(key) : '--';
      }
    },
    {
      prop: 'attritionFlag',
      label: 'projectProduct.isRemake',
      minWidth: '100px',
      formatter: (rowData: Object): string => {
        return (rowData as ProductRequestList).attritionFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    },
    {
      prop: 'areaPriceFlag',
      label: 'orderProduct.areaPriceFlag',
      minWidth: '100px',
      formatter: (rowData: Object): string => {
        return (rowData as ProductRequestList).areaPriceFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    }
  ];

  public makeQueryItemsOption: Array<OsQueryItemOption<ProductRequestQuery>> = [
    {
      type: 'Input',
      field: 'projectName',
      label: 'project.name',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'itemCode',
      label: 'product.code',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'itemName',
      label: 'product.name',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'itemType',
      label: 'billing.itemType',
      option: {
        placeholder: 'common.select'
      },
      optionData: billingService.getBillingItemTypes
    },
    {
      type: 'Select',
      field: 'priceUnit',
      label: 'billing.priceUnit',
      option: {
        placeholder: 'common.select'
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'attritionFlag',
      label: 'projectProduct.isRemake',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    }
  ];

  public makeQueryForm: Partial<ProductRequestQuery> = {};

  /**
   * 制作明细筛选后的数据
   */
  public makeFilteredData: Array<Partial<ProductRequestList>> = [];

  public makePaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public makeTotal = 0;

  public installTableOptions: OsTableOption<Partial<InstallRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'discountAmount',
        fixPlace: 2
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      }
      // {
      //   prop: 'taxAmount',
      //   fixPlace: 2
      // },
      // {
      //   prop: 'amountIncludingTax',
      //   fixPlace: 2
      // }
    ]
  };

  public installColumnOptions: Array<OsTableColumn<InstallRequestList>> = [
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'projectName',
      label: 'billing.relevanceProject',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'shopCode',
      label: 'customerShop.code',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'shopName',
      label: 'customerShop.name',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).priceBeforeTax ?? 0).toFixed(6);
      }
    },
    {
      prop: 'priceIncludingTax',
      label: 'billing.priceIncludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).priceIncludingTax ?? 0).toFixed(6);
      }
    },
    // {
    //   prop: 'taxRate',
    //   label: 'billing.taxRate',
    //   minWidth: '160px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).taxRate ?? 0).toFixed(2);
    //   }
    // },
    {
      prop: 'discountRate',
      label: 'billing.discountRate',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).discountRate ?? 0).toFixed(2);
      }
    },
    {
      prop: 'discountAmount',
      label: 'billing.discountAmount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).discountAmount ?? 0).toFixed(2);
      }
    },
    {
      prop: 'discountRemark',
      label: 'billing.discountRemark',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).amountExcludingTax ?? 0).toFixed(2);
      }
    },
    // {
    //   prop: 'taxAmount',
    //   label: 'billing.taxAmount',
    //   minWidth: '160px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).taxAmount ?? 0).toFixed(2);
    //   }
    // },

    // {
    //   prop: 'amountIncludingTax',
    //   label: 'billing.amountIncludingTax',
    //   minWidth: '180px',
    //   formatter: (rowData: Object): string => {
    //     return (rowData as InstallRequestList).amountIncludingTax?.toFixed(2);
    //   }
    // },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public installPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public installTotal = 0;

  public packageTableOptions: OsTableOption<Partial<PackageAndLogisticsRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'discountAmount',
        fixPlace: 2
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      }
      // {
      //   prop: 'taxAmount',
      //   fixPlace: 2
      // },
      // {
      //   prop: 'amountIncludingTax',
      //   fixPlace: 2
      // }
    ]
  };

  public packageColumnOptions: Array<OsTableColumn<PackageAndLogisticsRequestList>> = [
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'projectName',
      label: 'billing.relevanceProject',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'shopCode',
      label: 'customerShop.code',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'shopName',
      label: 'customerShop.name',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).priceBeforeTax ?? 0).toFixed(6);
      }
    },
    {
      prop: 'priceIncludingTax',
      label: 'billing.priceIncludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).priceIncludingTax ?? 0).toFixed(6);
      }
    },
    // {
    //   prop: 'taxRate',
    //   label: 'billing.taxRate',
    //   minWidth: '160px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).taxRate ?? 0).toFixed(2);
    //   }
    // },
    {
      prop: 'discountRate',
      label: 'billing.discountRate',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).discountRate ?? 0).toFixed(2);
      }
    },
    {
      prop: 'discountAmount',
      label: 'billing.discountAmount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).discountAmount ?? 0).toFixed(2);
      }
    },
    {
      prop: 'discountRemark',
      label: 'billing.discountRemark',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).amountExcludingTax ?? 0).toFixed(2);
      }
    },
    // {
    //   prop: 'taxAmount',
    //   label: 'billing.taxAmount',
    //   minWidth: '160px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).taxAmount ?? 0).toFixed(2);
    //   }
    // },

    // {
    //   prop: 'amountIncludingTax',
    //   label: 'billing.amountIncludingTax',
    //   minWidth: '180px',
    //   formatter: (rowData: Object): string => {
    //     return (rowData as PackageAndLogisticsRequestList).amountIncludingTax?.toFixed(2);
    //   }
    // },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public packagePaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public packageTotal = 0;

  public otherTableOptions: OsTableOption<Partial<OtherRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count',
        fixPlace: 2
      },
      {
        prop: 'discountAmount',
        fixPlace: 2
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      }
      // {
      //   prop: 'taxAmount',
      //   fixPlace: 2
      // },
      // {
      //   prop: 'amountIncludingTax',
      //   fixPlace: 2
      // }
    ]
  };

  public otherColumnOptions: Array<OsTableColumn<OtherRequestList>> = [
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'itemCode',
      label: 'product.code',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemName',
      label: 'product.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'billing.ownerProject',
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'billing.priceCount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).count ?? 0).toFixed(2);
      }
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).priceBeforeTax ?? 0).toFixed(6);
      }
    },
    {
      prop: 'priceIncludingTax',
      label: 'billing.priceIncludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).priceIncludingTax ?? 0).toFixed(6);
      }
    },
    {
      prop: 'discountRate',
      label: 'billing.discountRate',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).discountRate ?? 0).toFixed(2);
      }
    },
    {
      prop: 'discountAmount',
      label: 'billing.discountAmount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).discountAmount ?? 0).toFixed(2);
      }
    },
    {
      prop: 'discountRemark',
      label: 'billing.discountRemark',
      minWidth: '160px',
      showOverflowTooltip: true
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return ((rowData as ProductRequestList).amountExcludingTax ?? 0).toFixed(2);
      }
    },
    // {
    //   prop: 'taxAmount',
    //   label: 'billing.taxAmount',
    //   minWidth: '160px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as ProductRequestList).taxAmount ?? 0).toFixed(2);
    //   }
    // },

    // {
    //   prop: 'amountIncludingTax',
    //   label: 'billing.amountIncludingTax',
    //   minWidth: '180px',
    //   formatter: (rowData: Object): string => {
    //     return ((rowData as OtherRequestList).amountIncludingTax ?? 0).toFixed(2);
    //   }
    // },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];

  public otherPaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public otherTotal = 0;

  public invoiceTableOptions: OsTableOption<Partial<BillInvoiceRequestListInfo>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      },
      {
        prop: 'amountIncludingTax',
        fixPlace: 2
      },
      {
        prop: 'taxAmount',
        fixPlace: 2
      }
    ]
  };

  public invoiceColumnOptions: Array<OsTableColumn<BillInvoiceRequestListInfo>> = [
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'invoiceCode',
      label: 'billing.code',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'color',
      label: 'billing.color',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return translation(`invoiceColor.${InvoiceColorEnum[(rowData as BillInvoiceRequestListInfo).color]}`);
      }
    },
    {
      prop: 'invoiceNo',
      label: 'billing.invoiceNumber',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'billing.invoiceStatus',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: object): string => {
        if ((rowData as BillInvoiceRequestListInfo).status === 1) {
          return translation('financeInvoiceStatus.new');
        }
        return translation(
          `financeInvoiceStatus.${FinanceInvoiceStatusEnum[(rowData as BillInvoiceRequestListInfo).status]}`
        );
      }
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceRequestListInfo).amountExcludingTax?.toFixed(2);
      }
    },
    {
      prop: 'taxAmount',
      label: 'billing.taxAmount',
      minWidth: '160px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceRequestListInfo).taxAmount?.toFixed(2);
      }
    },
    {
      prop: 'amountIncludingTax',
      label: 'billing.amountIncludingTax',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceRequestListInfo).amountIncludingTax?.toFixed(2);
      }
    },
    {
      prop: 'invoiceType',
      label: 'billing.invoiceType',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return translation(`invoiceType.${InvoiceTypeEnum[(rowData as BillInvoiceRequestListInfo).invoiceType]}`);
      }
    },
    {
      prop: 'invoiceMethod',
      label: 'billing.invoiceMode',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return translation(`invoiceMode.${InvoiceModeEnum[(rowData as BillInvoiceRequestListInfo).invoiceMethod]}`);
      }
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public invoicePaging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public invoiceTotal = 0;

  /**
   * 选择交易明细
   */
  public selectTransactionDetailsDialog = false;

  public transactionTableOption: OsTableOption<TransactionList> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public transactionColumnOption: Array<OsTableColumn<TransactionList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'createTime',
      label: 'transaction.tradingHours',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (rowData: object): string => {
        return dateFormat((rowData as TransactionList).createTime);
      }
    },
    {
      prop: 'abstracts',
      label: 'transaction.abstract',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'serialNumber',
      label: 'transaction.serialNumber',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'type',
      label: 'transaction.transactionType',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).type) {
          return '--';
        }
        return translation(`transactionType.${TransactionTypeEnum[(rowData as TransactionList).type]}`);
      }
    },
    {
      prop: 'settlementMethod',
      label: 'billing.paymentMode',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).settlementMethod) {
          return '--';
        }
        return translation(`paymentMode.${PaymentModeEnum[(rowData as TransactionList).settlementMethod]}`);
      }
    },
    {
      prop: 'currencyName',
      label: 'transaction.transactionType',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'amount',
      label: 'transaction.amount',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as TransactionList).amount.toFixed(2);
      }
    },
    {
      prop: 'customerName',
      label: 'billing.customer',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'bankNumber',
      label: 'transaction.counterpartyAccount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'name',
      label: 'transaction.counterpartyName',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'bankName',
      label: 'transaction.counterpartyBank',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).status) {
          return translation('common.unKnownStatus');
        }
        return translation(`transactionStatus.${TransactionStatusEnum[(rowData as TransactionList).status]}`);
      }
    },
    {
      prop: 'billCode',
      label: 'transaction.writeOffBillingCode',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public transactionOperationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'billing.writeOff',
      operationType: 'writeOff',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'finance:bill:writeOff',
      dynamicHidden: this.allowWriteOff,
      handleClick: this.writeOff
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'billing.antiWriteOff',
      operationType: 'antiWriteOff',
      icon: 'el-icon-delete',
      permissionCode: 'finance:bill:cancelWriteOff',
      dynamicHidden: this.allowCancelWriteOff,
      plain: true,
      disabled: true,
      handleClick: this.antiWriteOff
    }
  ];

  /**
   * 结算单审批流程图
   */
  public approvalFlowChart = '';

  /**
   * 结算单审批结果
   */
  public approvalRecords: Array<Partial<TimelineItem & { content: string }>> = [];

  public get showApprovalFlowChart(): boolean {
    return this.approvalFlowChart !== '';
  }

  public get showApprovalCard(): boolean {
    return this.approvalFlowChart !== '' || this.approvalRecords.length > 0;
  }

  /**
   * 未税金额合计
   */
  public get amountExcludingTax(): string {
    let res = new Decimal(0);
    this.billing!.otherInfoResponses?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    this.billing!.installInfoResponses?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    this.billing!.packageAndLogisticsInfoResponses?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    this.billing!.productInfoResponses?.forEach(x => {
      res = res.add(new Decimal(x.amountExcludingTax));
    });
    return res.toFixed(2);
  }

  /**
   * 含税金额合计
   */
  public get amountIncludingTax(): string {
    const res = new Decimal(this.billing!.amountIncludingTax || 0);
    return res.toFixed(2);
  }

  public get statusName(): string {
    return BillingStatusEnum[this.billing!.status]
      ? translation(`billingStatus.${BillingStatusEnum[this.billing!.status]}`)
      : translation('common.unKnownStatus');
  }

  // public get currencyTypeName(): string {
  //   if (!this.billing!.currencyType) {
  //     return '';
  //   }
  //   return translation(`currencyTypes.${CurrencyEnum[this.billing!?.currencyType]}`);
  // }

  public get paymentModeName(): string {
    if (!this.billing!.settlementMethod) {
      return '';
    }
    return translation(`paymentMode.${PaymentModeEnum[this.billing!?.settlementMethod]}`);
  }

  /**
   * 是否允许提交审核
   */

  public get allowSubmit(): boolean {
    return this.billing!.status === BillingStatusEnum.new;
  }

  /**
   * 是否允许撤销
   */
  public get allowCancel(): boolean {
    return this.billing!.status === BillingStatusEnum.waitAudit;
  }

  public get showWriteOffRecords(): boolean {
    return (
      this.billing!.status === BillingStatusEnum.waitCancelAfterVerification ||
      this.billing!.status === BillingStatusEnum.complete
    );
  }

  public get allowCancelAudit(): boolean {
    return this.billing?.status === BillingStatusEnum.failedAbolishAudit;
  }

  public approveLoading = false;

  public get allowApprove(): boolean {
    if (!this.billing?.status) {
      return false;
    }
    const allowStatus = [BillingStatusEnum.waitAudit, BillingStatusEnum.abolishAudit];
    if (allowStatus.includes(this.billing.status)) {
      return true;
    }
    return false;
  }

  /**
   * 选中的已核销记录
   */
  private selectedTransactions: Array<TransactionList> = [];

  private approveParams!: ApproveParamter;

  public activated(): void {
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.initDetails();
    }
  }

  public created(): void {
    this.initDetails();
  }

  public filterMakeData(): void {
    this.makeFilteredData = this.billing?.productInfoResponses ?? [];
    if (this.makeQueryForm.projectName) {
      this.makeFilteredData = this.makeFilteredData.filter(x =>
        fuzzyQueryStr(this.makeQueryForm.projectName!, x.projectName!)
      );
    }
    if (this.makeQueryForm.pointName) {
      this.makeFilteredData = this.makeFilteredData.filter(x =>
        fuzzyQueryStr(this.makeQueryForm.pointName!, x.pointName!)
      );
    }
    if (this.makeQueryForm.itemCode) {
      this.makeFilteredData = this.makeFilteredData.filter(x =>
        fuzzyQueryStr(this.makeQueryForm.itemCode!, x.itemCode!)
      );
    }
    if (this.makeQueryForm.itemName) {
      this.makeFilteredData = this.makeFilteredData.filter(x =>
        fuzzyQueryStr(this.makeQueryForm.itemName!, x.itemName!)
      );
    }
    if (this.makeQueryForm.itemType) {
      this.makeFilteredData = this.makeFilteredData.filter(x => x.itemType === this.makeQueryForm.itemType);
    }

    if (this.makeQueryForm.priceUnit) {
      this.makeFilteredData = this.makeFilteredData.filter(x => x.priceUnit === this.makeQueryForm.priceUnit);
    }
    if (this.makeQueryForm.attritionFlag || this.makeQueryForm.attritionFlag === 0) {
      this.makeFilteredData = this.makeFilteredData.filter(x => x.attritionFlag === this.makeQueryForm.attritionFlag);
    }
    this.pagingMakeData();
  }

  public pagingMakeData(): void {
    this.makeTotal = this.makeFilteredData.length;
    this.makeTableOptions.data =
      this.makeFilteredData.slice(
        (this.makePaging.currentPage - 1) * this.makePaging.showCount,
        this.makePaging.currentPage * this.makePaging.showCount
      ) || [];
  }

  public handleMakeSortChange(sortOptions: SortOptions<ProductRequestList>): void {
    if (sortOptions.order === 'ascending') {
      this.makeFilteredData = this.makeFilteredData.sort((a, b) => {
        return (a[sortOptions.prop] as string).localeCompare(b[sortOptions.prop] as string, 'zh-CN');
      });
    } else if (sortOptions.order === 'descending') {
      this.makeFilteredData = this.makeFilteredData.sort((a, b) => {
        return (b[sortOptions.prop] as string).localeCompare(a[sortOptions.prop] as string, 'zh-CN');
      });
    }
    this.pagingMakeData();
  }

  public pagingInstallData(): void {
    this.installTotal = this.billing!.installInfoResponses?.length ?? 0;
    this.installTableOptions.data =
      this.billing!.installInfoResponses?.slice(
        (this.installPaging.currentPage - 1) * this.installPaging.showCount,
        this.installPaging.currentPage * this.installPaging.showCount
      ) || [];
  }

  public pagingPackageData(): void {
    this.packageTotal = this.billing!.packageAndLogisticsInfoResponses?.length ?? 0;
    this.packageTableOptions.data =
      this.billing!.packageAndLogisticsInfoResponses?.slice(
        (this.packagePaging.currentPage - 1) * this.packagePaging.showCount,
        this.packagePaging.currentPage * this.packagePaging.showCount
      ) || [];
  }

  public pagingOtherData(): void {
    this.otherTotal = this.billing!.otherInfoResponses?.length ?? 0;
    this.otherTableOptions.data =
      this.billing!.otherInfoResponses?.slice(
        (this.otherPaging.currentPage - 1) * this.otherPaging.showCount,
        this.otherPaging.currentPage * this.otherPaging.showCount
      ) || [];
  }

  public pagingInvoiceData(): void {
    this.invoiceTotal = this.billing!.billInvoiceInfoResponses?.length ?? 0;
    this.invoiceTableOptions.data =
      this.billing!.billInvoiceInfoResponses?.slice(
        (this.invoicePaging.currentPage - 1) * this.invoicePaging.showCount,
        this.invoicePaging.currentPage * this.invoicePaging.showCount
      ) || [];
  }
  public async submitApply(): Promise<void> {
    this.workflowDesign = (await workflowDesignService.getByTag('billTaskHandler')) ?? {};
    if (this.workflowDesign.id) {
      this.businessId = this.id;
      this.approvalDialogVisible = true;
    } else {
      this.$message.warning('当前业务没有设置审批任务');
    }
  }
  public updateData(): void {
    this.dataIsEdited = true;
    this.loadDetails(this.id);
  }

  /**
   * 审批
   */
  public approved(): void {
    showWarningConfirm(translation('todo.confirmApproved'))
      .then(async () => {
        this.setApproveLoading(true);
        const param: ApproveParamter = {
          ...this.approveParams,
          comment: '审批通过'
        };
        todoService
          .approved(param)
          .then(() => {
            Message.success(translation('operationRes.operationSuccess'));
            this.dataIsEdited = true;
            this.$router.go(-1);
            TagsViewModule.DelView(this.$route);
          })
          .catch(error => {
            messageError(error);
          })
          .finally(() => {
            this.setApproveLoading(false);
          });
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  /**
   * 驳回
   */
  public async reject(): Promise<void> {
    try {
      // 提示用户填写驳回原因
      const reasonObj = await this.$prompt(translation('todo.inputRejectReason'), translation('tip.tipInfo'), {
        confirmButtonText: translation('button.ok'),
        cancelButtonText: translation('button.cancel'),
        inputPlaceholder: translation('todo.inputRejectReason'),
        inputValidator: (value: string): boolean | string => {
          if (!value) {
            return translation('todo.inputRejectReason');
          }
          return true;
        }
      });
      const param: ApproveParamter = {
        ...this.approveParams,
        comment: (reasonObj as MessageBoxInputData).value
      };
      await todoService.reject(param);
      Message.success(translation('operationRes.operationSuccess'));
      this.dataIsEdited = true;
      this.$router.go(-1);
      TagsViewModule.DelView(this.$route);
    } catch (error) {
      if (error !== 'cancel') messageError(error);
    } finally {
      this.setApproveLoading(false);
    }
  }

  public cancel(): void {
    showWarningConfirm(translation('billing.confirmCancelApply'))
      .then(async () => {
        try {
          const params = {
            businessId: this.id,
            tag: 'billTaskHandler'
          };
          await todoTaskService.withdrawn(params);
          this.dataIsEdited = true;
          this.loadDetails(this.id);
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  /**
   * 取消废结
   */
  public async cancelAudit(): Promise<void> {
    try {
      const params = {
        businessId: this.id,
        tag: 'billRepealHandler'
      };
      await todoTaskService.withdrawn(params);
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }

  public openInvoiceDetailsDialog(invoiceDetails: BillInvoiceDetailRequestList): void {
    this.invoiceDetails = invoiceDetails;
    this.invoiceDetailsDialog = true;
  }

  /**
   * 处理核销成功后的回调
   * @param transactions 核销成功的交易明细
   */
  public handleWriteOffSuccess(transactions: TransactionList): void {
    this.transactionTableOption.data.unshift(transactions);
  }

  /**
   * 处理选中的已核销记录
   * @param transactions 核销成功的交易明细
   */
  public handleSelectionChange(transactions: Array<TransactionList>): void {
    this.selectedTransactions = transactions;
  }

  /**
   * 核销
   */
  public writeOff(): void {
    this.selectTransactionDetailsDialog = true;
  }

  /**
   * 反核销
   */
  public antiWriteOff(): void {
    showWarningConfirm(translation('billing.confirmAntiWriteOff'))
      .then(async () => {
        try {
          const ids: Array<number> = this.selectedTransactions.map(x => x.id);
          await billingService.antiWriteOff(this.billing!.id, ids);

          this.transactionTableOption.data = this.transactionTableOption.data.filter(x => !ids.includes(x.id));
          this.transactionTableOption.data.splice(0, 0);
          this.clearTransactionSelection();

          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  public loadDetails(id: number): void {
    if (!id) {
      return;
    }
    billingService
      .getById(id)
      .then(res => {
        this.billing = res;
        res.billInvoiceInfoResponses.forEach(x => {
          x.taxAmount = x.amountIncludingTax - x.amountExcludingTax;
        });
        this.makeFilteredData = this.billing.productInfoResponses ?? [];
        this.handleMakeSortChange(this.makeTableOptions.defaultSort!);
        this.pagingMakeData();

        this.pagingPackageData();
        this.pagingInstallData();
        this.pagingOtherData();
        this.pagingInvoiceData();
        if (this.showWriteOffRecords) {
          this.transactionTableOption.data = res.billWriteOffVOList;
        }
      })
      .catch(error => {
        messageError(error);
      });
  }

  public initDetails(): void {
    this.loadDetails(this.id);
    this.initActivitedParams();
    this.getPriceUnits();
  }

  private clearTransactionSelection(): void {
    this.selectedTransactions = [];
    (this.$refs.transactionTable as OsTable).clearSelection();
  }

  @Watch('selectedTransactions')
  private handleSelectedChanged(value: Array<BillingList>): void {
    const notControl = ['writeOff'];
    this.transactionOperationOptions.forEach(x => {
      if (!notControl.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  /**
   * 是否允许反核销
   */
  private allowCancelWriteOff(): boolean {
    const allowStatus = [BillingStatusEnum.waitCancelAfterVerification, BillingStatusEnum.complete];
    return !allowStatus.includes(this.billing!.status);
  }

  /**
   * 是否允许核销
   */
  private allowWriteOff(): boolean {
    return this.billing!.status !== BillingStatusEnum.waitCancelAfterVerification;
  }

  private beforeRouteLeave(to: Route, from: Route, next: Function): void {
    to.meta!.needReload = this.dataIsEdited;
    next();
  }

  private setApproveLoading(value: boolean): void {
    this.approveLoading = value;
  }

  private initActivitedParams(): void {
    this.approveParams = {
      id: Number(this.$route.query.id),
      pid: this.$route.query.pid as string,
      taskId: this.$route.query.taskId as string,
      scope: Number(this.$route.query.scope),
      type: Number(this.$route.query.type)
    };
  }

  private async getPriceUnits(): Promise<void> {
    const unitOptionData = await unitsMeasureService.getPriceUnits();
    this.makeQueryItemsOption.forEach(item => {
      if (item.field === 'priceUnit') {
        item.optionData = unitOptionData;
      }
    });
  }
}
