import { billingService, transactionService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import { TransactionTypeEnum, PaymentModeEnum, TransactionStatusEnum } from '@/resource/enum';
import { TransactionList, TransactionListQuery } from '@/resource/model';
import { dateFormat, messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectTransactionDetails extends mixins(PagingMixin, DialogMixin) {
  @Prop({ required: true, type: Number })
  public billId!: number;

  @Prop({ required: true, type: Number })
  public customerId!: number;

  public tableOption: OsTableOption<TransactionList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<TransactionList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'createTime',
      label: 'transaction.tradingHours',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (rowData: object): string => {
        return dateFormat((rowData as TransactionList).createTime);
      }
    },
    {
      prop: 'abstracts',
      label: 'transaction.abstract',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'serialNumber',
      label: 'transaction.serialNumber',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'type',
      label: 'transaction.transactionType',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).type) {
          return '--';
        }
        return translation(`transactionType.${TransactionTypeEnum[(rowData as TransactionList).type]}`);
      }
    },
    {
      prop: 'settlementMethod',
      label: 'billing.paymentMode',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).settlementMethod) {
          return '--';
        }
        return translation(`paymentMode.${PaymentModeEnum[(rowData as TransactionList).settlementMethod]}`);
      }
    },
    {
      prop: 'currencyName',
      label: 'transaction.transactionType',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'amount',
      label: 'transaction.amount',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as TransactionList).amount.toFixed(2);
      }
    },
    {
      prop: 'customerName',
      label: 'billing.customer',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'bankNumber',
      label: 'transaction.counterpartyAccount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'name',
      label: 'transaction.counterpartyName',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'bankName',
      label: 'transaction.counterpartyBank',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as TransactionList).status) {
          return translation('common.unKnownStatus');
        }
        return translation(`transactionStatus.${TransactionStatusEnum[(rowData as TransactionList).status]}`);
      }
    },
    {
      prop: 'billCode',
      label: 'transaction.writeOffBillingCode',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      },
      colSpan: 9
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'transaction.tradingHours',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      },
      colSpan: 9
    }
  ];

  private selectedRows: Array<TransactionList> = [];

  private queryForm: Partial<TransactionListQuery> & { createTime: Array<string> } = {
    customerId: this.customerId,
    keywords: '',
    createTime: []
  };

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.totalData})`;
  }

  public dialogOpen(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public created(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<TransactionList>): void {
    this.selectedRows = selectedData;
  }

  public onSubmit(): void {
    showWarningConfirm(translation('billing.confirmWriteOff'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await billingService.writeOff(this.billId, idList);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('write-off-success', this.selectedRows);
          this.closeDialog();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private loadData(): void {
    this.tableOption.loading = true;
    transactionService
      .getTransactionDetails(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private clearSelection(): void {
    this.selectedRows = [];
    (this.$refs.transactionDialogTable as OsTable).clearSelection();
  }
}
