import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { InvoiceColorEnum, InvoiceModeEnum, InvoiceTypeEnum } from '@/resource/enum';
import { BillInvoiceDetailRequestList, BillInvoiceRequestListInfo } from '@/resource/model';
import { translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import UploadAttachment from '@/views/finance-management/components/upload-attachment/upload-attachment.vue';

@Component({
  components: { UploadAttachment }
})
export default class InvoiceDetails extends mixins(DialogMixin) {
  @Prop({
    type: Object,
    required: true
  })
  public invoice!: BillInvoiceRequestListInfo;

  public downloadLoading = false;

  public tableOptions: OsTableOption<Partial<BillInvoiceDetailRequestList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count'
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      },
      {
        prop: 'amountIncludingTax',
        fixPlace: 2
      },
      {
        prop: 'taxAmount',
        fixPlace: 2
      }
    ]
  };

  public columnOptions: Array<OsTableColumn<BillInvoiceDetailRequestList>> = [
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'taxRevenueName',
      label: 'billing.taxRevenueName',
      minWidth: '180px'
    },
    {
      prop: 'serviceName',
      label: 'billing.serviceName',
      minWidth: '180px'
    },
    {
      prop: 'priceUnitName',
      label: 'billing.priceUnit',
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'billing.priceCount',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceDetailRequestList).count.toFixed(2);
      }
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceDetailRequestList).priceBeforeTax.toFixed(6);
      }
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceDetailRequestList).amountExcludingTax.toFixed(2);
      }
    },
    {
      prop: 'taxRate',
      label: 'billing.taxRate',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceDetailRequestList).taxRate.toFixed(2);
      }
    },
    {
      prop: 'taxAmount',
      label: 'billing.taxAmount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceDetailRequestList).taxAmount.toFixed(2);
      }
    },
    {
      prop: 'amountIncludingTax',
      label: 'billing.amountIncludingTax',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as BillInvoiceDetailRequestList).amountIncludingTax.toFixed(2);
      }
    }
  ];

  public get color(): string {
    return translation(`invoiceColor.${InvoiceColorEnum[this.invoice.color]}`);
  }

  public get invoiceMode(): string {
    return translation(`invoiceMode.${InvoiceModeEnum[this.invoice.invoiceMethod]}`);
  }

  public get invoiceType(): string {
    return translation(`invoiceType.${InvoiceTypeEnum[this.invoice.invoiceType]}`);
  }

  public created(): void {
    this.tableOptions.data = this.invoice.billInvoiceDetailRequestList;
  }

  // public async downloadInvoiceMaterial(): Promise<void> {
  //   this.downloadLoading = true;
  //   await minioService.init();
  //   const path = await minioService.getDownLoadPath(this.invoice.file);
  //   downloadFileByPath(translation('billing.invoiceMaterial'), path);
  //   this.downloadLoading = false;
  // }
}
